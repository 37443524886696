<template>
    <div class="">欢迎登录，{{userinfo?.name}}</div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex';

export default {
    setup () {
        let store = useStore();

        let userinfo = computed(() => store.getters.GET_USERINFO)

        return {
            userinfo
        }
    },
}
</script>